@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
.App {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Lato', sans-serif;
    // font-size: calc(10px + 2vmin);
    color: #f9f6d7;
    &__body {
        font: 700 2rem 'Lato', sans-serif;
    }
    p {
        font-size: 0.8rem;
        opacity: 0.7;
        margin: 0;
    }
    .portfolio {
        &__about-me {
            p {
                font-size: 1.3em;
                font-style: oblique;
                line-height: 1.7em;
                width: 90%;
                margin: 0 auto;
            }
        }
        &__contact-me {
            ul {
                list-style: none;
                display: flex;
                margin: 0;
                padding: 0;
                font-size: 1.3em;
                font-style: oblique;
                line-height: 1.7em;
                li {
                    margin: 0 15px;
                    a {
                        color: #f9f6d7;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
