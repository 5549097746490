.header {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    background: #834a80;
    &__name {
        display: flex;
        align-items: center;
        color: #fff;
        text-decoration: none;
        padding-left: 20px;
        img {
            width: 35px;
            height: 35px;
            margin-left: 5px;
        }
    }
    nav {
        display: flex;
        align-items: center;
        .menu-btn {
            display: none;
        }
        .menu-icon {
            display: none;
            @media only screen and (max-width: 479px) {
                cursor: pointer;
                display: inline-block;
                padding: 28px 20px;
                position: relative;
                user-select: none;
            }
            .navicon {
                background: #fff;
                display: block;
                height: 2px;
                position: relative;
                transition: background 0.2s ease-out;
                width: 18px;
                &::before,
                &::after {
                    background: #fff;
                    content: '';
                    display: block;
                    height: 100%;
                    position: absolute;
                    transition: all 0.2s ease-out;
                    width: 100%;
                }
                &::before {
                    top: -5px;
                }
                &::after {
                    top: 5px;
                }
            }
        }
    }
    &__links {
        list-style: none;
        margin: 0;
        clear: both;
        max-height: 0;
        transition: max-height 0.2s ease-out;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 479px) {
            position: absolute;
            padding: 0;
            right: 0;
            top: 64px;
            flex-direction: column;
            width: 100%;
            height: 200px;
            background: #834a80;
        }

        &__item {
            margin: 0 10px;
            @media only screen and (max-width: 479px) {
                display: none;
            }
            a {
                color: #fff;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
.menu-btn:checked ~ .header__links {
    max-height: 240px;
}
.menu-btn:checked ~ .header__links .header__links__item {
    display: block;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}
